import React, { useContext } from "react";
import { SpeeltuinchefContext } from "../../store/Store";
import { Link } from "react-router-dom";
import {
  getPlaygroundCurrentPhaseName,
  sortPlaygroundAlphabetical,
} from "../../helpers/PlaygroundHelper";
import { MapViewPlaygrounds } from "./MapViewPlaygrounds";

const PLAYGROUND_COUNT_LIMIT = process.env.REACT_APP_PLAYGROUND_COUNT_LIMIT
  ? parseInt(process.env.REACT_APP_PLAYGROUND_COUNT_LIMIT)
  : undefined;

export const PlaygroundsList = () => {
  const context = useContext(SpeeltuinchefContext);

  const { state } = context;
  const getPlaygroundsList = (): React.ReactNode => {
    if (!state.playgrounds) {
      return [];
    } else {
      const sortedPlaygrounds = state.playgrounds.sort(
        sortPlaygroundAlphabetical
      );
      return sortedPlaygrounds.map((playground) => {
        return (
          <Link
            key={"playground-" + playground.id}
            to={"/playgrounds/" + playground.id + "/general"}
            className={"playgroundslist__item"}
          >
            <div className={"playgroundslist__value"}>{playground.name}</div>
            <div className={"playgroundslist__value"}>
              {getPlaygroundCurrentPhaseName(playground, state.phases)}
            </div>
            <div className={"playgroundslist__value"}>
              <img src={"/assets/images/menu/pencil.svg"} alt={"Potlood"} />
            </div>
          </Link>
        );
      });
    }
  };
  return (
    <div className={"block playgroundslist"}>
      <div className={"block__title"}>
        <h1>Speeltuinen overzicht</h1>
        {PLAYGROUND_COUNT_LIMIT &&
          state.playgrounds !== null &&
          `${state.playgrounds.length} van de ${PLAYGROUND_COUNT_LIMIT} speeltuinen in gebruik`}
      </div>
      <div className={"block__content"}>
        <div className={"block__right"}>
          {PLAYGROUND_COUNT_LIMIT &&
          state.playgrounds !== null &&
          state.playgrounds.length >= PLAYGROUND_COUNT_LIMIT ? (
            <p className={"message message--warning"}>
              Maximum aantal speeltuinen bereikt. Neem contact op om uw
              abonnement uit te bereiden.
            </p>
          ) : (
            <Link to={"/playgrounds/new"}>
              <button className={"button"}>Nieuwe speeltuin aanmaken</button>
            </Link>
          )}
        </div>
        <div className={"playgroundlist__playgrounds"}>
          <div className={"playgroundslist__header"}>
            <div className={"playgroundslist__value"}>Naam</div>
            <div className={"playgroundslist__value"}>Fase</div>
          </div>
          {getPlaygroundsList()}
        </div>
        <div className={"playgroundslist__map"}>
          {state.playgrounds !== null && state.playgrounds.length > 0 ? (
            <MapViewPlaygrounds playgrounds={state.playgrounds} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
